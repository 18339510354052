@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

:root {

	--brand-color-1: #f9f0e0;
	--brand-color-2: #825e44;
	--brand-color-2-dark: #614632;
	--brand-color-3: #d4a965;
	--brand-color-3-light: #ead6b5;
	--brand-color-3-dark: #a5772e;
	--brand-color-6 : #832e6e;

	--font-family: "Apex New";

	--header-bg-color: var(--brand-color-1);
	--header-font-color: var(--brand-color-2);
	--header-links-color: var(--brand-color-2);
	--login-card-bg-color: var(--brand-color-1);
	--login-card-button-bg-color: var(--brand-color-3);
	--login-card-button-hover-bg-color: var(--brand-color-3-dark);
	--login-card-button-disabled-bg-color: var(--brand-color-3-light);
	--login-card-button-font-color: var(--brand-color-4);
	--login-card-font-color: var(--brand-color-2);
	--login-card-label-color: var(--brand-color-2);
	--popover-triangle-bg-color: var(--brand-color-2);
	--popover-link-font-color: var(--brand-color-2);
	--popover-link-hover-color: var(--brand-color-2-dark);
	--popover-triangle-color: var(--brand-color-1);
	--pos-login-insurance-link-color: var( --brand-color-6 );
}

@font-face {
    font-family: 'Apex New';
    src: url('../styles/fonts/ApexNew-Book.otf') format("opentype");
}

@font-face {
    font-family: 'Apex New';
    src: url('../styles/fonts/ApexNew-Medium.otf') format("opentype");
    font-weight: bold;
}

@font-face {
    font-family: 'FS-Joey';
    src: url('../styles/fonts/FS-Joey-Regular.otf') format("opentype");
}

@font-face {
    font-family: 'FS-Joey';
    src: url('../styles/fonts/FS-Joey-Bold.otf') format("opentype");
    font-weight: bold;
}
// Bootstrap Colors
$gray-base:                 #000 !default;
$gray-darker:               lighten($gray-base, 13.5%) !default; // #222
$gray-dark:                 #373a3c !default;
$gray:                      #55595c !default;
$gray-light:                #818a91 !default;
$gray-lighting:             lighten($gray-base, 75%) !default;
$gray-lighter:              #eceeef !default;
$gray-lightest:             #f7f7f9 !default;
$gray-zero-opacity: 		rgba(240, 245, 248, 0);

// Colors
$light-gray:                #f0f5f8 !default;
$light-grey:                #d0d0d0 !default;
$sky-blue:                  #c7e5f6 !default;
$water-blue:                #087dc2 !default;
$aqua-blue:                 #80CEFE !default;
$dark-gray:                 #838383 !default;
$royal-red:                 #ec2227 !default;
$pale-green:                #D6EFB6 !default;
$light-green:				#dff0d8 !default;
$green:						#3c763d !default;
$lighter-sky-blue:			#87ceeb !default;

$light-green:				#dff0d8 !default;
$green:						#3c763d !default;

$dark-blue:                 #3b5998 !default;
$dark-orange:               #dd4b39 !default;

$dark-green:				#008000 !default;
$bar-green:					#90ee90 !default;

$dark:                      #000 !default;
$light:                     #fff !default;
$activated:					#23527c !default;
$white-color:				#FFFFFF !default;
$blue:					    #0473b8 !default;
$darker-gray:            	#908e8e !default;
$orange-color:				#ff9800 !default;
$dark-red:					#FB041B !default;
$dark-brown:				#825e44 !default;
$light-brown:				rgb(212, 169, 101)!default;
$light-cream:				#f9f0e0 !default;
$dark-black	:				#000000 !default;
$lighter-green:				#7fba50 !default;
$lightest-green:			#EDF6E3 !default;
$shade1-green:				#60BB46 !default;
$secondary_button:			linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
$dark-purple:				#9c1981 !default;
$light-purple:				#e34fc6 !default;
$shade1-orange:				#f15a23 !default;
$royal-pink:				#c71585 !default;
$saffron:                   #F5D76E !default;

$brand-color-1:             $royal-red ;  // $royal-red
$brand-color-2:             $water-blue;  // $water-blue
$brand-color-3:             $sky-blue ;  // $sky-blue
$brand-color-4:             $light-gray ;  // $light-gray
$brand-color-5:             $dark-gray ;  // $dark-gray
$brand-color-6:             $aqua-blue ;  // $aqua-blue
$brand-color-7:             $pale-green;  // $pale-green
$brand-color-8:             $dark-blue;  // $dark-blue
$brand-color-9:             $dark-orange;  // $dark-orange
$brand-color-10:            $light-grey;  // $light-grey
$brand-color-11:            $light; //$light
$brand-color-12:            $dark-green; //$dark-green
$brand-color-13:            $bar-green; //$bar-green
$brand-color-14:            $lighter-sky-blue; 
$brand-color-15:            $activated; 
$brand-color-16:            $blue; 
$brand-color-17:            $darker-gray;
$brand-color-20:            $white-color;
$brand-color-21:            $dark-brown;
$brand-color-22:            $gray-dark;
$brand-color-23:            $saffron;
$brand-color-26:            $gray-zero-opacity; 
$brand-color-27:            $white-color; 
$brand-color-28:            $orange-color;
$brand-color-29:            $dark-red;
$brand-color-30:            $dark-brown;
$brand-color-31:            $light-brown;
$brand-color-32:          	$light-cream;
$brand-color-33:             #DECECE;
$brand-color-34:            $dark-black;
$brand-color-35:            $lighter-green;
$brand-color-36:            $lightest-green;
$brand-color-37:            $shade1-green;//Buttons
$brand-color-38:            $secondary_button;//Buttons
$brand-color-39:            $dark-purple;
$brand-color-40:            $shade1-orange;
$brand-color-41:            $light-purple;
$brand-color-42:            $royal-pink;
$brand-color-19:            $orange-color;


$body-bg:                   #EEEFF3 !default;
$body-color:                $dark-gray !default;

$text-color:                $dark-gray !default;
$text-muted:                $light-gray !default;

$link-color:				$brand-color-2 !default;
$link-hover-color:			darken($link-color, 15%) !default;
$link-hover-decoration:		underline !default;

$font-size-root:            16px !default;

$font-size-base:            14px !default;

$font-size-h1:				2.25rem !default; // 36px
$font-size-h2:				1.5rem !default; // 24px
$font-size-h3:				1.125rem !default; // 18px
$font-size-h4:				0.875rem !default; // 14px
$font-size-h5:				0.75rem !default; // 12px
$font-size-h6:				0.625rem !default; // 10px
$font-size-sign-small:		0.3750rem !default; //6px

$font-size-number:			1.375rem !default; // 22px
$font-size-number-small:	1.125rem !default; // 22px

$font-weight-base:          600 !default;

$font-family-landing-page-sans-serif:	var(--font-family), Helvetica, Arial, sans-serif !default;
$font-family-sans-serif:	"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-base:			$font-family-sans-serif !default;
$font-family-number:		"Open Sans";

$line-height:               1.5 !default;

$enable-hover-media-query:  false !default;

$spacer:					1rem !default;
$spacer-x:					$spacer !default;
$spacer-y:					$spacer !default;
$border-width:				.0625rem !default;

$headings-margin-bottom:	calc($spacer / 2) !default;

$hr-border-color:			rgba(0,0,0,.1) !default;
$hr-border-width:			$border-width;

$grid-padding:				1.875rem !default; // 30px
$base-padding:				1.875rem !default; //30px
$base-margin:				1.875rem !default; //30px

$advertise-bgcolor:			$light !default;

$star-rating:				#F3C103;

$xs-screen-size:			0px;
$sm-screen-size:			1024px;
$md-screen-size:			1280px;
$lg-screen-size:			1600px;

$xs-width:					auto; 
$sm-width:					960px;
$md-width:					1200px;
$lg-width:					1400px;

$modal-xs-size:				320px !default;
$modal-sm-size:				900px !default;
$modal-md-size:				1000px !default;
$modal-lg-size:				1200px !default;


$confirmed-text-color:		green !default;
$waiting-text-color:		#FFBF00 !default;
$cancelled-text-color:		red !default;

$ticketed-row-color:		lighten(green,70);//#e6ffe6;
$cancelled-row-color:		$brand-color-4;
$row-shadow-color:			0 0 15px rgba(0, 0, 0, .25) !default;
$input-border-color:		lighten($brand-color-5, 30);
$cashier-liability-color:   #fad8d8;
